.Header {
  width: 100%;
  overflow-x: hidden;

  .Header-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;
    padding-top: 5em;
    background-color: rgba(0, 0, 0, 0.51);
  }

  .Header-overlayBottom {
    z-index: -1;
    height: 300px;
    width: 100%;
    background-image: linear-gradient(
      to top,
    rgba(0,0,0,0),
    rgba(0,0,0,.1),
    rgba(0,0,0,.2),
    rgba(0,0,0,.51)
    );
  }

  .Header-logo {
    margin-bottom: 1rem;
  }

  .Header-getInTouch {
    margin-bottom: 5rem;
  }
  /*color: white;

  .Header-location, .Header-phone, .Header-email {
    display: inline-flex;

    .Header-icon {
      margin-right: 0.5rem;
    }
  }

  .Header-contactInfo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 2rem;

    a {
      color: inherit;
    }
  }*/
}
