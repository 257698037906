.Stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9.5rem;
  color: white;

  /*.Stat-value {
    color: rgb(167, 0, 0);
  }
  .Stat-label {
    color: white;
  }*/
}
