.Footer {
    width: 100%;
    padding: 1.6rem;
    background-color: #111111;
    color: #cccccc;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Footer-text {
        font-size: 0.6rem;
    }
}