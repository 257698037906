.Service {
    position: relative;
    overflow-x: hidden;

    .Service-imageContainer {
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 500px;
    }

    .Service-overlay {
        background-color: #0f0f1f99;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .Service-text {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        color: white;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .Service-subtitle {
            font-weight: 600;
            font-size: 1.1rem;
            opacity: 0;
            text-transform: uppercase;
            color: #6c1f1d;
            margin-bottom: 0.2rem;
        }
        .Service-title {
            font-size: 1.6rem;
            margin-bottom: 1.1rem;
            opacity: 0;
            text-transform: uppercase;
        }
        .Service-details {
            color: #dadada;
            margin-bottom: 1.1rem;
            opacity: 0;
        }
        .Service-rightTextAlign {
            text-align: right;
        }
        .Service-features {
            margin-bottom: 1.1rem;

            .Service-feature {
                opacity: 0;

                .Service-arrow {
                    margin-right: 0.4rem;
                    display: inline;
                    fill: #6c1f1d;
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
        .Service-getAQuote {
            width: fit-content;
            opacity: 0;
        }
    }

    .Service-rightAlign {
        align-items: end;
    }
}

@media only screen and (min-width: 40rem) {
    .Service {
        .Service-imageContainer {
            height: 400px;
        }
        .Service-text {
            padding: 0 4rem;
        }
    }
}
