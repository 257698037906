.Contact {
    width: 100%;

    .Contact-cardContainer {
        padding: 12rem 0.8rem 0rem 0.8rem;
        display: flex;
        justify-content: center;
        margin-bottom: -8rem;

        .Contact-card {
            background-color: white;
            padding: 3rem 2rem;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            row-gap: 3rem;
            justify-content: center;

            .Contact-col1 {
                max-width: 21rem;
                display: flex;
                flex-direction: column;

                .Contact-title {
                    font-size: 3rem;
                    line-height: 3rem;
                    margin-bottom: 4rem;
                }
                .Contact-desc {
                    .Contact-desc1 {
                        margin-bottom: 0.5rem;
                    }
                }
                .Contact-col1Space {
                    flex-grow: 1;
                    height: 4rem;
                }
                .Contact-details {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .Contact-el {
                        .Contact-lbl {
                            font-weight: 600;
                            margin-bottom: 0.5rem;
                        }
                        .Contact-val {
                            a {
                                color: inherit;
                            }
                        }
                    }
                }
            }
            .Contact-col2 {
                display: flex;
                flex-direction: column;

                .Contact-formField {
                }
                .Contact-textArea {
                    resize: none;
                    height: 6rem;
                }
                .Contact-nameLabel {

                }
                .Contact-nameBox {

                }
                .Contact-emailLabel {

                }
                .Contact-emailBox {

                }
                .Contact-messageLabel {

                }
                .Contact-messageBox {

                }
                .Contact-sendButton {

                }
            }
        }
    }

    .Contact-whitespace {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8rem;
        padding-bottom: 3rem;
        background-color: white;

        .Contact-map {
            border: 0;
            width: 21.4rem;
            height: 12rem;
        }
    }
}

@media only screen and (min-width: 40rem) {
    .Contact {
        .Contact-whitespace {
            .Contact-map {
                width: 24.6rem;
                height: 13.8rem;
            }
        }
    }
}

@media only screen and (min-width: 48rem) {
    .Contact {
        .Contact-cardContainer {
            .Contact-card {
                flex-direction: row;
                column-gap: 3rem;
                flex-wrap: nowrap;

                .Contact-col1 {
                    .Contact-col1Space {
                        height: auto;
                    }
                }
                .Contact-col2 {
                    .Contact-formField {
                        width: 20rem;
                    }
                }
            }
        }
        .Contact-whitespace {
            padding-bottom: 5rem;

            .Contact-map {
                width: 46.4rem;
                height: 24rem;
            }
        }
    }
}

@media only screen and (min-width: 64rem) {
    .Contact {
        .Contact-cardContainer {
            .Contact-card {
                column-gap: 10rem;
                .Contact-col2 {
                    .Contact-formField {
                        width: 21rem;
                    }
                }
            }
        }
        .Contact-whitespace {
            padding-bottom: 7rem;

            .Contact-map {
                width: 56rem;
                height: 28rem;
            }
        }
    }
}
