.About {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .About-sloganMore {
    font-size: 2rem;
    letter-spacing: 1.25rem;
    margin-right: -1.25rem;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: -2rem;
    line-height: 0%;
    color: aliceblue;
    text-shadow: 0px 0px 3px black;
  }

  .About-sloganFloors {
    font-size: 6rem;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 0px 0px 16px white;
  }

  .About-sloganVision {
    font-size: 2rem;
    text-shadow: 0px 0px 16px white;
  }

  .About-descriptionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(240, 255, 255, 0.8);
    margin-top: 6rem;
    padding: 2rem 6rem;

    .About-description {
      text-align: center;
      width: 21rem;
    }
    .About-descriptionText1 {
      margin-bottom: 1.6rem;
    }
    .About-descriptionText2 {
    }
  }
}

@media only screen and (min-width: 40rem) {
  .About {
    .About-sloganMore {
      font-weight: 300;
      font-size: 4.02rem;
      letter-spacing: 1.75rem;
      margin-right: -1.75rem;
    }
    .About-sloganFloors {
      font-size: 10rem;
    }
    .About-sloganVision {
      font-size: 3rem;
    }
    .About-descriptionContainer {
      .About-description {
        width: 38rem;
      }
    }
  }
}

@media only screen and (min-width: 48rem) {
  .About {
    .About-sloganMore {
      font-size: 5.12em;
      letter-spacing: 1.92rem;
      margin-right: -1.92rem;
    }
    .About-sloganFloors {
      font-size: 12rem;
    }
    .About-sloganVision {
      font-size: 3.8rem;
    }
    .About-descriptionContainer {
      .About-description {
        width: 45rem;
      }
    }
  }
}

@media only screen and (min-width: 64rem) {
  .About {
    .About-sloganMore {
      font-size: 6.5rem;
      letter-spacing: 1.9rem;
      margin-right: -1.9rem;
    }
    .About-sloganFloors {
      font-size: 14rem;
    }
    .About-sloganVision {
      font-size: 4.5rem;
    }
    .About-descriptionContainer {
      .About-description {
        width: 51rem;
      }
    }
  }
}
