.Stats {
  display: flex;
  column-gap: 0rem;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (min-width: 40rem) {
  .Stats {
    column-gap: 1rem;
    flex-wrap: nowrap;
  }
}

@media only screen and (min-width: 48rem) {
  .Stats {
    column-gap: 2rem;
    flex-wrap: nowrap;
  }
}
