.Testimonials {
    width: 100%;
/*    height: 40rem;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000088;
    /*padding: 8rem 4rem*/

    .Testimonials-card {
        position: absolute;
        background-color: #ffffff;
        padding: 2rem;
/*        width: 50rem;*/
        /*height: 272px;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Testimonials-text {
            margin-bottom: 2rem;
            text-align: center;
        }
        .Testimonials-stars {
            display: flex;

            .Testimonials-star {
                width: 2rem;
                height: 2rem;
                fill: #fdd663;
            }
            .Testimonials-space {
                margin-right: 0.2rem;
            }
        }
        .Testimonials-author {
        }
    }
}

/*@media only screen and (min-width: 40rem) {
    .Testimonials-card {
        width: 40rem;
    }
  }
  
  @media only screen and (min-width: 48rem) {
    .Testimonials-card {
        width: 50rem;
    }
  }
*/
