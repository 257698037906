.Partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  width: 100%;
  background-color: white;
  color: #282c34;
  overflow-x: hidden;

  .Partners-title {
    margin-bottom: 1.3rem;
    font-size: 3rem;
  }

  .Partners-footer {
    margin-top: 0.8rem;
    font-size: 3rem;
  }

  .Partners-logoRow {
    display: flex;
    justify-content: center;
  }
  .Partners-logo1 {
    padding-right: 2.3rem;
  }
  .Partners-logo2 {
    padding-right: 1.5rem;
  }
  .Partners-logo4 {
    padding-left: 1.3rem;
    padding-right: 2.5rem;
  }
  .Partners-logo5 {
    padding-right: 0.65rem;
  }
}
