.App {
  display: flex;
  flex-direction: column;
  align-items: center;

  .App-hideNavBar {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 31rem;
  }

  .App-refContainer {
    width: 100%;
  }

  .App-bgVid {
    z-index: -10;
    position: fixed;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .App-bgContact {
    z-index: -9;
    position: fixed;
    top: 0;
    display: block;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
}
